import React from "react";
import "./Header.css";
import { AppBar, Box, Toolbar, Typography, Container } from "@mui/material";
import LogoHeader from "../../Components/LogoHeader/LogoHeader";
import MenuHeader from "../../Components/MenuHeader/MenuHeader";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import { GlobalContext } from "../../Context/GlobalContext";

/**
 * Contenedor del header: <br>
 * Logo, menú y barra de progreso
 * @class Layout/Header
 * @example
 * <Header />
 * @returns {React.component} Header
 */
function Header() {
  const { cssApi, customApi, menuApi, isDebugging } = React.useContext(GlobalContext);

  /**
   * Estilos del header
   */
  const AppBarStyle = {
    backgroundColor: cssApi.header_background
  };

  return (
    <Box className="chatbothr_header">
      <AppBar position="static" color="default" sx={AppBarStyle}>
        <Container fixed maxWidth="md">
          <Toolbar>
            <LogoHeader img={customApi.logo}  alt={customApi.client}/>
            <Typography component="div" sx={{ flexGrow: 1 }} />
            <MenuHeader menuList={menuApi} debugging={isDebugging} />
          </Toolbar>
        </Container>
      </AppBar>
      <ProgressBar />

    </Box>
  );
}

export default Header;
