import React from 'react';
import { Helmet } from 'react-helmet-async';
import { GlobalContext } from "../../Context/GlobalContext";

/**
 * Contenedor que se encarga de inyectar la hoja css personalizada y los estilos generales de la aplicación.
 * @class Layout/CssGlobal
 * @example
 * <CssGlobal>
      <Header />
      <ContainerChat />
      ...
    </CssGlobal>
 * @param {*} children los componentes anidados
 * @returns {React.Component} CssGlobal
 */
function CssGlobal({ children, widgetMode=false }) {
  const { cssApi, extraCssApi } = React.useContext(GlobalContext);

  /**
   * Estilo global de la aplicación. 
   */ 
  const AppStyle = {
    background: cssApi.background,
    fontFamily: cssApi.font_family,
    fontSize: cssApi.font_size
  };

  return (
    <div className="chatbothr_app" style={AppStyle}>
      <Helmet>
        <style>{extraCssApi}</style> 
        {!widgetMode && <style>{"body {position: relative!important; padding: 0!important; overflow-y: scroll!important; -webkit-overflow-scrolling: touch;}"}</style> }   
      </Helmet>
      {children}
    </div>
  );
}

export default CssGlobal;
