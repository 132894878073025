import React from 'react';
import { styled } from '@mui/material/styles';
import { Backdrop, CircularProgress } from '@mui/material';
import { GlobalContext } from "../../Context/GlobalContext";

/**
 * Componente loading inicial de la app: <br>
 * Contiene la animación de carga de la aplicación inicial.
 * @class Layout/LoadingPage
 * @example
 * import LoadingPage from '../Layout/LoadingPage/LoadingPage';
 * <LoadingPage />
 * @returns {React.Component} LoadingPage
 */
function LoadingPage() {

  const { isLoading } = React.useContext(GlobalContext);

  /**
   * Componente Backdrop de MUI con styled aplicado
   */
  const LoadingBackdrop = styled(Backdrop)({
    position: "absolute",
    backgroundColor: '#ffffff',
    height: "100%",
    width: "100%",
    color: '#A9A9A9',
    zIndex: 1650,
  });

  return (
    <LoadingBackdrop open={isLoading} data-testid="CircularProgress">
      <CircularProgress color="inherit" />
    </LoadingBackdrop>
  );
}

export default LoadingPage;