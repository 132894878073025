import React from "react";
import { styled } from "@mui/material/styles";
import { Divider, IconButton, CircularProgress } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";

/**
 * Componente de subida de ficheros: <br>
 * Botón de selección de los ficheros y llamada al servicio de subida de ficheros.
 * @class Components/AttachFileButtons
 * @example
 * const {cssApi} = React.useContext(GlobalContext);
 * <AttachFileButtons cssApi={cssApi} />
 * @param {*} cssApi estilos cargados desde el api
 * @returns {React.Fragment} AttachFileButtons
 */
function AttachFileButtons({ cssApi, uploadFiles, multiple, isLoading = false }) {
  // const { customApi, pushChatMessages } = React.useContext(GlobalContext);
  // const [isLoading, setIsLoading] = React.useState(false);
  const inputFile = React.useRef(null);

  /**
   * Maneja el evento onClick del botón y lanza el input tipo file
   */
  const handleClickAttachFile = () => {
    inputFile.current.click();
  };

  /**
   * Maneja el evento onSelect del input y envía la lista de ficheros
   */
  const handleSelect = (event) => {
    uploadFiles([...event.target.files]);
  };

  /**
   * Componente IconButton de MUI con styled aplicado
   */
  const AttachIconButton = styled(IconButton)({
    color: cssApi.input_placeholder_color,
    p: "10px",
  });

  const getMultiple = () => {
    console.log("multiple", multiple);
    return multiple ? {multiple: true} : {};
  }

  return (
    <React.Fragment>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      {isLoading ? (
        <CircularProgress
          aria-label="circular_progress"
          style={{ color: "grey", padding: "8px" }}
        />
      ) : (
        <AttachIconButton
          aria-label="attachFile"
          onClick={handleClickAttachFile}
        >
          <AttachFileIcon></AttachFileIcon>
          <input
            data-testid="input_attach"
            id="input_attach"
            {...getMultiple()}
            type="file"
            ref={inputFile}
            style={{ display: "none" }}
            onChange={handleSelect}
          />
        </AttachIconButton>
      )}
    </React.Fragment>
  );
}

export default AttachFileButtons;
