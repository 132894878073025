import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { GlobalContext } from "../../Context/GlobalContext";
import { SESSION } from "../../Constants/Global";

/**
 * Componente lista del menuú del header:  <br>
 * Render de cada item de la lista
 * @class Components/MenuListHeader
 * @example
 * import MenuListHeader from "../MenuListHeader/MenuListHeader";
 * <MenuHeader menuList={menuApi} />
 * @param {*} item Objeto con los datos del item de cada menú
 * @returns {React.Fragment} MenuListHeader
 */

function MenuListHeader({ item, afterClick }) {
  const { pushChatMessages } = React.useContext(GlobalContext);

  const onClickOptionHandler = (item) => {
    if (item.action === "URL") {
      window.open(item.value, "_blank");
    } else if (item.action === "postback") { // Timer block postback options
      pushChatMessages(
        {
          messages: [{ selected_text: item.label, type_text: "text" }],
          postback: item.value,
          text: item.label,
        },
        "user"
      );
    } else if (item.action === "remove_session") {
      window.removeSession();
    }else if (item.action === "view_session") {
      window.open('https://api-gero.hrbotfactory.com/gero/load/'+localStorage.getItem(SESSION), "_blank");
    }
    afterClick && afterClick();
  };

  return (
    <MenuItem onClick={onClickOptionHandler.bind(null, item)}>
      {item.label}
    </MenuItem>
  );
}

window.removeSession = () =>{
  if(localStorage.getItem(SESSION)) {
    localStorage.removeItem(SESSION);
    window.location.reload();
  }
}

export default MenuListHeader;