import React from "react";
import { styled } from "@mui/material/styles";
import { InputBase, IconButton, Divider } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
//import EmojiButtons from "../EmojiButtons/EmojiButtons";
import { useTranslation } from 'react-i18next';
import { preventScrolling, formatDate } from "../../Utils/Utils";
import PhoneCodesSelect from "../PhoneCodesSelect/PhoneCodesSelect";
import {GlobalContext} from "../../Context/GlobalContext";

/**
 * Componente de botones e interacción de la barra de input: <br>
 * Botoneras e input del chat: emojis, input tipo text, numeric o date, y botones de adjunto y cámara.
 * @class Components/InputUserButtons
 * @example
 * <InputUserButtons 
    cssApi={cssApi} 
    pushChatMessages={pushChatMessages} 
    inputPlaceholder={inputPlaceholder}
    inputDisabled={inputDisabled}
    inputType={inputType}
  />
 * @param {*} cssApi estilos desde el API
 * @param {*} pushChatMessages función de añadir mensajes al chat
 * @param {*} inputPlaceholder texto del placeholder
 * @param {*} inputType tipo de input
 * @param {*} inputDisabled habilitar o deshabilitar el input
 * @returns {React.Fragment} InputUserButtons
 */
function InputUserButtons({
  inputPlaceholder,
  inputType,
  inputDisabled,
}) {
  const { cssApi, pushChatMessages, isFinished } = React.useContext(GlobalContext);
  const {t} = useTranslation();
  const regex2clean = /(<([^>]+)>)/ig;
  const [inputText, setInputText] = React.useState("");
  const [disabledButton, setDisabledButton] = React.useState(true);
  /*const [disabledEmoji, setDisabledEmoji] = React.useState(false);

  React.useEffect(() => {
    
    if(inputType === "date" || inputType === "number" || inputDisabled)
      setDisabledEmoji(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputType]);
  */

  /**
   * Maneja el evento onClick del modal de emojis, añade el icono seleccionado al input
   * @param {*} emoji objeto emoji
   */
  /*const handleclickEmoji = (emoji) => {

    if(inputText.trim() === ""){
      setInputText(emoji);
    }else{
      setInputText(inputText + " " + emoji);
    }
    setDisabledButton(false);
  }*/
  /**
   * Maneja los eventos onChange del input
   * @param {*} event evento que se dispara al interactuar con el input
   */
  const handleInputChange = (event) => {
    let value = event.target.value.replace(regex2clean, '');

    if (inputType === "tel"){
      value = value.replace(/[\D]/g, "");
    }else if (inputType === "date"){
      setDisabledButton(false);
    }

    setInputText(value);

    if(value.trim() === ""){
      setDisabledButton(true);
    }else{
      setDisabledButton(false);
    }
  };
  /**
   * Maneja el evento onClick del botón de enviar mensaje, añade el mensaje al chat
   */
  const handleClickSend = () => {
    pushMsg();
  };
  /**
   * Maneja el evento onKeyDown del input, añade el mensaje al chat al presionar enter
   * @param {*} event
   */
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();

      if(inputText !== "")
        pushMsg();
    }
  };
  /**
   * Añade los mensajes al chat y limpia el input
   */
  const pushMsg = () => {
    const phoneCode = inputType === "tel" && document.querySelector(".country-code")? document.querySelector(".country-code").textContent : "";
    let selected_text = inputType === "date" ? formatDate(inputText) : phoneCode + inputText.replace(regex2clean, '');
    pushChatMessages(
      {
        messages: [{ selected_text: selected_text, type_text: "text" }],
        postback: selected_text,
        text: selected_text,
      },
      "user"
    );
    setInputText("");
  };
  /**
   * Componente IconButton de MUI con los estilos aplicados
   */
  const SendIconButton = styled(IconButton)({
    color: cssApi.input_placeholder_color,
    p: "10px",
  });

  return (
    <React.Fragment>
      {/*<EmojiButtons
        cssApi={cssApi}
        handleclickEmoji={handleclickEmoji}
        disabled={disabledEmoji}
      />*/}
      {inputType === "tel" && <PhoneCodesSelect />}
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <InputBase
        sx={{ ml: 1, flex: 1, paddingTop: inputType === "date" ? "0" : undefined, }}
        placeholder={inputPlaceholder && inputPlaceholder.length ? inputPlaceholder : t("inputPlaceholder")}
        value={inputText}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onClick={preventScrolling}
        onFocus={preventScrolling}
        inputProps={{
          className: "input-user",
          "aria-label": "Enviar Mensaje",
          inputMode: inputType === "number" ? "Numeric" : inputType === "tel" ? "tel": "text"
        }}
        disabled={inputDisabled || isFinished}
        type={inputType}
      />
      <SendIconButton
        type="button"
        aria-label="send"
        onClick={handleClickSend}
        disabled={disabledButton || isFinished}
      >
        <SendIcon />
      </SendIconButton>
    </React.Fragment>
  );
}

export default InputUserButtons;
