import React from "react";
import "./ButtonPanel.css";
import { Button } from "@mui/material";
import { GlobalContext } from "../../Context/GlobalContext";
import parse from 'html-react-parser';

/**
 * Componente de botonera: <br>
 * Botonera en forma de lista
 * @class Components/ButtonPanel
 * @example
 * <ButtonPanel items={options} forceDisabled={isDisabled}/>
 * @returns {React.Component} ButtonPanel
 */
function ButtonPanel({ items, forceDisabled = false }) {
  const { cssApi, pushChatMessages } = React.useContext(GlobalContext);
  const [disabledBtn, setDisabledBtn] = React.useState(false);
  /**
   * Estilos de los botones
   */
  const getBtnCSS = function(){
    return  {
      height: "auto",
      width: "100%",
      marginTop: "8px",
      fontSize: "1em !important",
      background: cssApi.button_background,
      color: cssApi.button_color,
      border: cssApi.button_border,
      borderRadius: cssApi.button_border_radius,
      padding: '8px 12px',
      wordWrap:'break-word',
      overflowWrap: 'anywhere',
      textTransform: 'none',
      "&:hover": {
        background: cssApi.button_background_selected,
        color: cssApi.button_color_selected,
        border: cssApi.button_border,
      },
      "&:disabled": {
        opacity: "0.70"
      }
    };
  }
  /**
   * Maneja el evento onSubmit del formulario, hace los postback bloquea los botones una vez pulsados
   * @param {*} item un objeto del menú de botones
   */
  const handleSubmit = (item) => {
    if(/^https?:\/\//.test(item.postback)){
      window.open(item.postback, '_blank');
    }else if(/^event:/.test(item.postback)){
      const eventInfo = item.postback.replace("event:", "");
      const [event, detail] = eventInfo.split("|")
      document.dispatchEvent(new CustomEvent(event, {"detail": detail }));
    }else{
      pushChatMessages({ messages: [{selected_text: item.text, type_text: 'text' }], postback: item.postback, text: item.text}, 'user');
      setDisabledBtn(true);
    }
  };

  return (
    <div className="buttonPanel-div">
      {items.map((item) => (
          <Button
          key={item.id}
            onClick={handleSubmit.bind(null, item)}
            variant="outlined"
            className="buttonPanel-div__button"
            sx={getBtnCSS()}
            disabled={disabledBtn || forceDisabled}
          >
            {parse(item.text)}
          </Button>
      ))}
    </div>
  );
}

export default ButtonPanel;