import React from 'react';
import './Timer.css';
import { styled } from '@mui/material/styles';
import AlarmIcon from '@mui/icons-material/Alarm';
import { TimerContext } from '../../Context/TimerContext'

const TimerBlock = styled('div')(({ className, style }) => ({
      color: "#666",
      marginTop: "2px",
      textAlign: "right",
      fontSize: className === "big" ? "1.6rem" :  "0.8rem",
      ...style
    }));
const ClockIcon = styled(AlarmIcon)(({ className }) => ({
  fontSize: className === "big" ? "1.6rem" :  "0.8rem",
  top: "0.1rem",
  right:"0.1rem",
  position: "relative"
  }));


/**
 * Componente del Timer: <br>
 * Evento en el cual simula el tiempo que tiene el usuario para responder. Si el contador llega a 0 se lanza el evento callback.
 * @returns {React.Fragment} Timer
 */
const Timer = ({className, style = {}}) => {
  const { timerState } = React.useContext(TimerContext);

  // Esta función convierte los segundos a formato mm:ss
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;

    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };


  return (
      <TimerBlock className={className} style={style}>
        <ClockIcon fontSize="small" className={className}/>
        <span>{formatTime(timerState.time)}</span>
      </TimerBlock>
  );
}

export { Timer };
