import React from "react";
import useAxios from "../../Hooks/useAxios";
import { 
  Box,
  CardMedia, 
  Fab,
  Typography } from "@mui/material";
import { FileOpen } from "@mui/icons-material";
import LayoutMessage from "../LayoutMessage/LayoutMessage";
import { GlobalContext } from "../../Context/GlobalContext";
import parse from 'html-react-parser';

/**
 * Componente Bocadillo user: <br>
 * Render del bocadillo del usuario en el container del chat.
 * @class Components/MessageUser
 * @example
 * import MessageUser from "../../Components/MessageUser/MessageUser";
 * <MessageUser msg={msg} key={index.toString()} />
 * @param {*} msg Respuesta del mensaje que viene del API.
 * @param {*} key Index del recorrido de la lista.
 * @returns {React.Fragment} MessageUser
 */

function MessageUser({ msg }) {
  const { customApi, pushChatMessages, setIsTyping, isHistoric } = React.useContext(GlobalContext);

  const { data, isLoading } = useAxios('reply', { session: customApi.session, postback: msg.postback, text: msg.text });

  React.useEffect(() => {
    if (!isLoading) {
      pushChatMessages(data, "chatbot");
    }else if(!isHistoric) {
      setIsTyping(true, true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  /**
   * Estilo parrafo del bocadillo del usuario.
   */
  /* const StyleFileName = {
    wordWrap:'break-word',
    overflowWrap: 'anywhere',
    marginTop: '10px',
    textAlign: 'center'
  } */
  /**
   * Estilos de cardmedia.
   */
  const cardmediaStyle = (inputType) => {
    if(inputType === "audio"){
      return { marginTop: "15px", height: "4rem", width: "18em", objectFit: "scale-down" }
    }else {
      return { marginTop: "15px", height: "33vh", objectFit: "scale-down", borderRadius: "15px" }
    }
  }

  return (
    <React.Fragment>
      {(msg.messages && msg.messages.length > 0) &&
        msg.messages.map((item, index) => (
        <LayoutMessage withAvatar={false} typeUser={2} key={index}>
          {item.type_text === 'text' && 
            <Typography align='left' sx={{ fontSize: "1em", wordWrap:'break-word', overflowWrap: 'anywhere' }}>{parse(item.selected_text)}</Typography>
          }
          {(item.type_text === 'img' || item.type_text === 'video' || item.type_text === 'audio') && 
            <>
            <CardMedia
              component={item.type_text}
              sx={cardmediaStyle(item.type_text)}
              image={item.fileUrl}
              controls={item.type_text==='video' || item.type_text==='audio'}
            />
            </>
          }          
          {item.type_text === 'file' &&
            <Box sx={{ textAlign:'center' }}>
            <Fab
              aria-label="save"
              color="default"
              href={item.fileUrl} 
              target="_blank"
            >
              <FileOpen />
            </Fab>
              {/*< Typography align='left' sx={StyleFileName}>{item.fileName}</Typography>}*/}
            </Box>
          }
        </LayoutMessage>
      ))}
    </React.Fragment>
  );
}

export default MessageUser;
