import React from "react";
const TimerContext = React.createContext();

/**
 * @class Context/TimerContext
 */

const TimerProvider = (props) => {
  const timerInitialState = {
    time: 60, // Tiempo inicial en segundos
    init: false,
    initialTime: 60, // Tiempo inicial en segundos
    isRunning: false,
    callback: null
  };

  const timerReducer = (state, action) => {
    const callback = (state.callback || action.callback);

    switch (action.type) {
      case 'START':
        return {
          ...state,
          init: true,
          callback: action.callback || null,
          time: (action.time || state.time),
          isRunning: true
        };

      case 'STOP':
        callback && callback();

        return { ...state, isRunning: false };

      case 'END':
        callback && callback();
        state.init = false;
        return { ...state, init: false, time: 0, isRunning: false };

      case 'RESET':
        return { ...state, time: state.initialTime, isRunning: false };

      case 'TICK':
        return { ...state, time: state.time - 1 };

      default:
        return state;
    }
  };

  const [timerState, dispatchTimer] = React.useReducer(timerReducer, timerInitialState);
  React.useEffect(() => {
    let timerId;

    if (timerState.isRunning && timerState.time > 0) {
      timerId = setTimeout(() => {
        dispatchTimer({ type: 'TICK' });
      }, 1000);
    } else if (timerState.isRunning && timerState.time === 0) {
      dispatchTimer({ type: 'STOP' });
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [timerState.isRunning, timerState.time]);

  return (
    <TimerContext.Provider
      value={{
        timerState,
        dispatchTimer
      }}
    >
      {props.children}
    </TimerContext.Provider>
  );
}

export { TimerContext, TimerProvider };
