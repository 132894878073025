import React from "react";
import MessageChatBot from "../../Components/MessageChatBot/MessageChatBot";
import { MAX_DELAY, MULTIPLIER_DELAY } from "../../Constants/Global";
import { GlobalContext } from "../../Context/GlobalContext";
import MessageUser from "../MessageUser/MessageUser";
import { checkType, focusInput } from '../../Utils/Utils';
import { TimerContext } from '../../Context/TimerContext'

/**
 * Componente de lista de mensajes: <br>
 * Renderiza una lista de mensajes de forma síncrona para simular los tiempos de typing, mostrando este mientras cargan los siguientes mensajes.
 * @class Components/MessageChatBotList
 * @example
 * <MessageChatBotList
      chatbotMsg={msg}
      setChatProgress={setChatProgress}
      key={index.toString()}
    />
 * @param {*} chatbotMsg lista de mensajes
 * @param {*} setChatProgress progreso de la conversación
 * @returns {React.Fragment} MessageChatBotList
 */
function MessageChatBotList({ chatbotMsg, setChatProgress }) {
  const [delayMessage, setDelayMessage] = React.useState([]);
  const { pushChatMessageTimeout, isHistoric, setIsHistoric, setIsTyping, recordingRef, isUploadingRef } = React.useContext(GlobalContext);
  const { dispatchTimer } = React.useContext(TimerContext);

  React.useEffect(() => {
    setChatProgress(chatbotMsg.progress);
    fetching();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatbotMsg]);

  /**
   * Devuelve una promesa que simula el delay entre cada mensaje.
   * @param {*} ms tiempo de delay
   * @returns {Promise} promesa con el timeout establecido
   */
  const wait = (ms) => new Promise((r, j) => setTimeout(r, ms));

  /**
   * Renderiza un mensaje con el delay que nos indique. Si no tiene delay se calcula, y si es carga de histórico se ignora.
   * @param {*} msg objeto mensaje
   * @param {*} index posición en la lista de pensajes
   * @returns {React.Fragment} MessageChatBot
   */
  const fetchBotMsg = async (msg, index) => {
    if (!isHistoric) {
      let delayTime = msg.typing_time;
      if (!delayTime) {
        delayTime = Math.min(
          MAX_DELAY,
          msg.selected_text.length * MULTIPLIER_DELAY
        );
      }
      await wait(delayTime);
    }

    // Forzamos timeout en caso de que sea una pregunta con tiempo, o que venga del histórico
    // y el usuario no haya contestado
    if((!isHistoric && msg.reply_time) || (msg.reply_time && !msg.user_reply)){
      dispatchTimer({
        type: isHistoric ? "END" : "START",
        time: isHistoric ? 0 : msg.reply_time,
        callback: ()=>onTimeOut(msg)
      });
    }

    return (
      <React.Fragment key={index.toString()}>
        <MessageChatBot msg={msg} key={index.toString()} />
        {"user_reply" in msg && displayUserMsg(msg, index.toString())}
      </React.Fragment>
    );
  };

  /**
   * Función para autocontestar en caso de que se agote el tiempo.
   */
  const onTimeOut = (msg) => {
    if(!msg.user_reply) { // Comprobamos que no haya contestado
      if(recordingRef.current){
        msg.user_reply = "uploading";
        return recordingRef.current();
      }

      if(isUploadingRef.current){
        return;
      }

      pushChatMessageTimeout();
    }
  };

  /**
   * Gnenera un mensaje de respuesta del usuario o una lista de previews
   * @param {*} msg lista de mensajes
   * @returns {Array} lista de mensajes formateados
   */
  const generateMessages = (msg) => {
    let messages = [];
    if (msg.response_type === "file") {
      const urls = msg.user_reply.text.split(",");

      urls.forEach((url) => {
        let parts = url.split("/");
        let fileName = parts[parts.length - 1];

        messages.push({
          fileUrl: url,
          fileName: fileName,
          type_text: checkType(fileName),
        });
      });
    } else if (msg.user_reply.text) {
      messages = [{ selected_text: msg.user_reply.text, type_text: "text" }];
    }

    return messages;
  };

  /**
   * Genera la lista de mensajes o previews y devuelve el componente de mensaje del usuario
   * @param {*} msg lista de mensajes
   * @param {*} idx posición en la lista total de mensajes
   * @returns {React.Component} MessageUser
   */
  const displayUserMsg = (msg, idx) => {
    const ms = {
      messages: generateMessages(msg),
      postback: null,
    };
    return <MessageUser msg={ms} key={"user_" + idx} />;
  };

  /**
   * Genera la lista de mensajes poco a poco respentando el delay entre cada uno de ellos
   */
  const fetching = async () => {
    let lista = [];
    if(!isHistoric) setIsTyping(true);
    await chatbotMsg.data.reduce(async (previousPromise, msg, index) => {
      await previousPromise;
      msg.withAvatar = index === 0;
      msg.key = index;
      const data = await fetchBotMsg(msg, index);
      lista = [...lista, data];
      setDelayMessage(lista);
      return Promise.resolve();
    }, Promise.resolve());

    focusInput("input-user");
    setIsTyping(false);
    setTimeout(()=>setIsHistoric(false), 500); // Important: Sin esto se queda typing despues de importar el historial
  };

  return (
    <React.Fragment>
      {delayMessage}
    </React.Fragment>
  );
}

export default MessageChatBotList;
