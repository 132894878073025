import React from "react";
import "./AudioButtons.css";
import { styled } from "@mui/material/styles";
import { IconButton, CircularProgress } from "@mui/material";
import AudioIcon from "@mui/icons-material/Mic";

/**
 * Componente de input de audio: <br>
 * Botón de capturar audio.
 * @class Components/AudioButtons
 * @example
 * const {cssApi} = React.useContext(GlobalContext);
 * <AudioButtons cssApi={cssApi} />
 * @param {*} cssApi estilos cargados desde el api
 * @returns {React.Fragment} AudioButtons
 */
function AudioButtons({ cssApi, uploadFiles, isLoading = false }) {
  const inputAudio = React.useRef(null);
  const mimeType = "video/mp4";
  const fileName = "audio.mp4";
  const [micPermission, setMicPermission] = React.useState(false);
  const mediaRecorder = React.useRef(null);
  const [recordingStatus, setRecordingStatus] = React.useState("inactive");
  const [stream, setStream] = React.useState(null);
  const [audioChunks, setAudioChunks] = React.useState([]);

  React.useEffect(() => {
    getMicrophonePermission();
  }, []);

  /**
   * Maneja el evento onClick del botón y lanza el input de audio.
   */
  const handleClickAudioCapture = () => {
    inputAudio.current.click();
  };

  /**
   * Maneja el evento onSelect del input y envía el audio.
   */
  const handleChange = (event) => {
    uploadFiles([...event.target.files]);
  };

  /**
   * Comprueba compatibilidad y permisos
   */
  const getMicrophonePermission = async () => {
    if ("MediaRecorder" in window && "mediaDevices" in navigator) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({audio: true, video: false});
        setStream(streamData);
        setMicPermission(true);
      } catch (err) {
        console.log("Microphone device error: " + err.message);
      }
    } else {
      console.log("MediaRecorder/navigator.mediaDevices API not supported.");
    }
  };  

  /**
   * Inicia la grabación
   */
  const startRecording = async () => {
    setRecordingStatus("recording");
    
    const media = new MediaRecorder(stream, { type: mimeType });
    mediaRecorder.current = media;
    mediaRecorder.current.start();

    let localAudioChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
    };

    setAudioChunks(localAudioChunks);
  }  

  /**
   * Para la grabación y envía el fichero
   */
  const stopRecording = () => {
    setRecordingStatus("inactive");
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = () => {
      const audioBlob = new Blob(audioChunks, { type: mimeType });
      const file = new File([audioBlob], fileName, { type: audioBlob.type });
      uploadFiles([file]);
      setAudioChunks([]);
    };
  };

  /**
   * Componente IconButton de MUI con styled aplicado
   */
  const AudioIconButton = styled(IconButton)({
    color: cssApi.input_placeholder_color,
    p: "10px",
  });

  return (
    <React.Fragment>
      {isLoading ? (
        <CircularProgress
          aria-label="circular_progress"
          style={{ color: "grey", padding: "8px" }}
        />
      ) : 
      micPermission? 
      (
        <>
          {recordingStatus === "inactive" && (
            <AudioIconButton aria-label="audiocapture" onClick={startRecording}>
              <AudioIcon />
            </AudioIconButton>
          )}
          {recordingStatus === "recording" && (
            <AudioIconButton aria-label="audiocapture" onClick={stopRecording} className="blinks" sx={{color: "red"}}>
              <AudioIcon />
            </AudioIconButton>
          )}
        </>
      ) 
      : (
        <AudioIconButton
          aria-label="audiocapture"
          onClick={handleClickAudioCapture}
        >
          <AudioIcon />
          <input
            data-testid="audio_capture"
            id="audio_capture"
            style={{ display: "none" }}
            type="file"
            accept="audio/*"            
            capture
            ref={inputAudio}
            onChange={handleChange}
          />
        </AudioIconButton>
      )
      }
    </React.Fragment>
  );
}

export default AudioButtons;
