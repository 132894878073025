import React from "react";
import "./MultiSelect.css";
import { Autocomplete, Button, TextField } from "@mui/material";
import { GlobalContext } from "../../Context/GlobalContext";
import { WIDGET_MODE } from "../../Constants/Global";
import SendIcon from "@mui/icons-material/Send";

/**
 * Componente del input tipo Select: <br>
 * Render del selemento select.
 * @class Components/MultiSelect
 * @example
 * import MultiSelect from "../MultiSelect/MultiSelect";
 * <MultiSelect
 *      items={options}
 *      placeholder={help_text}
 *      forceDisabled={isDisabled} />
 * @param {*} items Lista de las opciones del select.
 * @param {*} multiple Flag que define si el select es multiselect.
 * @param {*} placeholder Define el texto de ayuda.
 * @param {*} forceDisabled Variable para deshabilitar ele select si es historico.
 * @returns {React.Fragment} MultiSelect
 */

function MultiSelect({
  items,
  multiple = true,
  placeholder,
  forceDisabled = false,
}) {
  const { cssApi, pushChatMessages, isFinished } = React.useContext(GlobalContext);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [disabledInput, setDisabledInput] = React.useState(false);
  const [disabledBtn, setDisabledBtn] = React.useState(true);

  /**
   * Estilo css del Select personalizable del API.
   */
  const multiSelectCSS = {
    width: "100%",
    textTransform: "none",
    "& .MuiOutlinedInput-root": {
      "& > fieldset": { borderColor: "#c1c1c1" },
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#c1c1c1",
    },
    "&:hover .MuiInputLabel-root": {
      color: "#c1c1c1",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#c1c1c1",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#c1c1c1",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#c1c1c1",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#c1c1c1",
    },
    "& .MuiButtonBase-root": {
      background: cssApi.button_background,
      // background: "rgb(255 0 0 / 43%);",
      color: cssApi.button_color,
    },
    "& .MuiInputBase-root": {
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "0px",
    },
  };

  /**
   * Estilo css del boton personalizable del API.
   */
  const btnSelectCSS = {
    height: "auto",
    width: "100%",
    textTransform: "none",
    background: cssApi.button_background,
    // background: "rgb(255 0 0 / 43%);",
    color: cssApi.button_color,
    border: cssApi.button_border,
    borderRadius: cssApi.button_border_radius,
    padding: '8px 12px',
    "&:hover": {
      background: cssApi.button_background_selected,
      // background: "rgb(255 0 0 / 43%);",
      color: cssApi.button_color_selected,
      border: cssApi.button_border,
    },
  };

  /**
   * Evento para deshabilitar o habilitar el boton de enviar
   * @param {*} event
   * @param {*} value Valor del input
   */
  const handleChange = (event, value) => {
    setSelectedOptions(value);

    if (multiple) {
      setDisabledBtn(value.length > 0 ? false : true);
    } else {
      setDisabledBtn(value ? false : true);
    }    
  };
  /**
   * Maneja el evento onOpen del selector, bloquea el scroll del body
   */
  const handleOpen = () => {
    if (!WIDGET_MODE) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      window.document.body.style =
        "overflow-y:hidden!important;touch-action: none!important";
    }
  };
  /**
   * Maneja el evento onClose del selector, desbloquea el scroll del body
   */
  const handleClose = () => {
    if (!WIDGET_MODE) {
      window.document.body.style = "overflow-y:scroll!important";
    }
  };
  /**
   * Evento para enviar las opciones seleccionadas a Reply
   * @param {*} event Evento que dispara el elemento
   */

  const handleSubmit = (event) => {
    event.preventDefault();

    let optionsString = "";
    let postbackString = "";
    if (multiple) {
      /*optionsString = selectedOptions.reduce(function (prevVal, currVal, idx) {
        return idx === 0 ? currVal.text : prevVal + ", " + currVal.text;
      }, "");
      postbackString = selectedOptions.reduce(function (prevVal, currVal, idx) {
        return idx === 0 ? currVal.postback : prevVal + "; " + currVal.postback;
      }, "");*/
      optionsString = selectedOptions.map(e=> e.text).join(", ")
      postbackString = selectedOptions.map(e=> e.postback);
    } else {
      optionsString = selectedOptions.text;
      postbackString = selectedOptions.postback;
    }
    pushChatMessages(
      {
        messages: [{ selected_text: optionsString, type_text: "text" }],
        postback: postbackString,
        text: optionsString,
      },
      "user"
    );
    setDisabledInput(true);
    setDisabledBtn(true);
  };

  return (
    <form onSubmit={handleSubmit} className="multiSelect-form">
      <Autocomplete
        disablePortal
        fullWidth
        multiple={multiple}
        autoComplete={true}
        autoHighlight={true}
        id="multiSelect"
        options={items}
        ListboxProps={{ style: { maxHeight: "15rem" }}}
        disableCloseOnSelect={false}
        sx={multiSelectCSS}
        getOptionLabel={(option) => option.text}
        onChange={handleChange}
        onOpen={handleOpen}
        onClose={handleClose}
        renderOption={(props, option, { selected }) =>
          !selected && (
            <li {...props}>
              <Button variant="outlined" sx={btnSelectCSS}>
                {option.text}
              </Button>
            </li>
          )
        }
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} />
        )}
        disabled={disabledInput || isFinished || forceDisabled}
      />
      <Button
        type="submit"
        aria-label="submit"
        variant="outlined"
        className="multiSelect-form__submit"
        sx={btnSelectCSS}
        disabled={disabledBtn || isFinished || forceDisabled}
      >
        <SendIcon />
      </Button>
    </form>
  );
}

export default MultiSelect;