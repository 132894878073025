import React from 'react';
import './LogoHeader.css';
import { styled } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia';

/**
 * Componente de logo: <br>
 * Imagen de logo que se situa en el header
 * @class Components/LogoHeader
 * @example
 * <LogoHeader img={customApi.logo}  alt={customApi.client}/>
 * @param {*} img imagen desde el API
 * @param {*} alt alternativa si no hay imagen
 * @returns {React.Component} LogoHeader
 */

function LogoHeader({ img, alt }) {
  /**
   * Componente CardMedia con los estilos aplicados
   */
  const HeaderLogo = styled(CardMedia)({
    height: '45px',
    objectFit: 'contain !important',
    width: '140px'
  });

  return img ? (
    <HeaderLogo
      component="img"
      className="chatbothr_header__logo"
      image={img}
      alt={"Logo " + alt}
    />
  ) : null;
}

export default LogoHeader;