import React from "react";
import "./Carousel.css";
import SwipeableViews from "react-swipeable-views";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  MobileStepper,
  Typography,
  Button,
} from "@mui/material";
import {
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
} from "@mui/icons-material";
import { GlobalContext } from "../../Context/GlobalContext";
import parse from "html-react-parser";

/**
 * Componente de carrusel: <br>
 * Slide de tarjetas con foto y botoneras que envían el postback o abren un enlace
 * @class Components/Carousel
 * @example
 * const msg = {items: [{},{}]}
 * <Carousel items={msg.items} forceDisabled={"user_reply" in msg}/>
 * @param {*} items lista de items que se renderizan
 * @param {*} forceDisabled fuerza desactivar los botones para los casos en que se requiera
 * @returns {React.Component} Carousel
 */
function Carousel({ items, forceDisabled = false }) {
  const { cssApi, pushChatMessages } = React.useContext(GlobalContext);
  const [activeStep, setActiveStep] = React.useState(0);
  const [disabledBtn, setDisabledBtn] = React.useState(false);

  /**
   * Establece el carrusel a la primera posición
   */
  React.useEffect(() => {
    setActiveStep(0);
  }, []);
  /**
   * Maneja el evento onNext del carrusel
   */
  const onNextHandler = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  /**
   * Maneja el evento onBack del carrusel
   */
  const onBackHandler = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  /**
   * Maneja el evento onChange del carrusel, establece la posción indicada
   * @param {*} step objeto de una posición en el carrusel
   */
  const onChangeStepHandler = (step) => {
    setActiveStep(step);
  };
  /**
   * Maneja el evento onSelect al clicar una opción. Si es una url abre una pestaña, si es un postback escribe y envía el mensaje
   * @param {*} step objeto de una posición en el carrusel
   */
  const onSelectHandler = (step) => {
    if(/^https?:\/\//.test(step.postback)){
      window.open(step.postback, '_blank');
    }else if(/^event:/.test(step.postback)) {
      const eventInfo = step.postback.replace("event:", "");
      const [event, detail] = eventInfo.split("|")
      document.dispatchEvent(new CustomEvent(event, {"detail": detail }));
    }else {
      pushChatMessages({ messages: [{selected_text: step.text, type_text: 'text' }], postback: step.postback, text: step.text}, 'user');
      setDisabledBtn(true);
    }
  };
  /**
   * Estilos de botones del stepper
   */
  const btnStepCSS = {
    background: cssApi.button_background,
    color: cssApi.button_color,
    "&:hover": {
      background: cssApi.button_background_selected,
      color: cssApi.button_color_selected,
    },
  };
  /**
   * Estilos de botones de selección
   */
  const getBtnCSS = function(text){
    let fontSize = "0.8em !important";
    if(text && text.length > 50){
      fontSize = "0.6em !important";
    }
    return {
      background: cssApi.button_background,
      border: cssApi.button_border,
      borderRadius: cssApi.button_border_radius,
      padding: '8px 12px',
      fontSize: fontSize,
      color: cssApi.button_color,
      height: 'auto',
      wordWrap:'break-word',
      overflowWrap: 'anywhere',
      "&:hover": {
        background: cssApi.button_background_selected,
        border: cssApi.button_border,
        color: cssApi.button_color_selected,
      },
      "&:disabled": {
        opacity: "0.70",
      },
    };
  }

  /**
   * Estilos de las tarjetas
   */
  const selectCardStyle = {
    bgcolor: cssApi.chatbox_background_bot,
    border: cssApi.chatbox_border,
    borderRadius: cssApi.chatbox_border_radius,
    color: cssApi.chatbox_color_bot,
  };
  /**
   * Estilo dinámico del padding, en función de la posición actual
   * @returns {*} objeto de estilos con el padding calculado
   */
  const paddingEffect = () => {
    return activeStep === items.length - 1
      ? {
          position: "relative",
          zIndex: 1,
          paddingLeft: "10%",
          transition: "0.33s padding ease",
        }
      : activeStep === 0
      ? {
          position: "relative",
          zIndex: 1,
          paddingRight: "10%",
          transition: "0.33s padding ease",
        }
      : {
          position: "relative",
          zIndex: 1,
          paddingRight: "5%",
          paddingLeft: "5%",
          transition: "0.33s padding ease",
        };
  };
  /**
   * Botonera de una tarjeta
   * @param {*} step objeto de una posición en el carrusel
   * @param {*} index posición del carrusel
   * @returns Button
   */
  const displayButtons = (step, index) => {
    if(step.options && step.options['text']){
      step.options = [step.options];
    }
    return (
      step.options &&
      step.options.length > 0 &&
      step.options.map((btn, idx) => (
        <Button
          variant="outlined"
          className="carousel-select__btn"
          sx={getBtnCSS(btn.text)}
          onClick={onSelectHandler.bind(null, btn)}
          key={idx}
          disabled={disabledBtn || forceDisabled}
        >
          {btn.text}
        </Button>
      ))
    );
  };

  return (
    <div className="MessageChatBot carousel">
      <SwipeableViews
        axis="x"
        index={activeStep}
        onChangeIndex={onChangeStepHandler}
        enableMouseEvents
        style={paddingEffect()}
      >
        {items.map((step, index) => (
          <div key={step.id}>
            {Math.abs(activeStep - index) <= 2 && 
              <Card className="carousel-card" sx={selectCardStyle}>
                <CardMedia
                  component="img"
                  className="carousel-card__img"
                  src={step.url}
                  alt={step.title}
                />
                {(step.title || step.description) && <CardContent sx={{ minHeight: 120 }}>
                  {step.title && <Typography gutterBottom variant="subtitle1" component="div" className="carousel-title">
                    {step.title}
                  </Typography>}
                  {step.description && <Typography className="carousel-description scroll" gutterBottom variant="body1" component="div">
                    {parse(step.description)}
                  </Typography>}
                </CardContent>}
                <CardActions>{displayButtons(step, index)}</CardActions>
              </Card>
            }
          </div>
        ))}
      </SwipeableViews>
      <MobileStepper
        variant={null}
        steps={items.length}
        position="static"
        activeStep={activeStep}
        className="carousel-stepper"
        nextButton={
          activeStep !== items.length - 1 && (
            <Button
              aria-label="nextButton"
              className="carousel-stepper__btn"
              size="small"
              onClick={onNextHandler}
              disabled={activeStep === items.length - 1}
              hidden={activeStep === items.length - 1}
              sx={btnStepCSS}
            >
              <ArrowForwardIosRounded />
            </Button>
          )
        }
        backButton={
          activeStep !== 0 ? (
            <Button
              aria-label="backButton"
              className="carousel-stepper__btn"
              size="small"
              onClick={onBackHandler}
              disabled={activeStep <= 0}
              hidden={activeStep <= 0}
              sx={btnStepCSS}
            >
              <ArrowBackIosNewRounded />
            </Button>
          ) : (
            <div></div>
          )
        }
      />
    </div>
  );
}

export default Carousel;
