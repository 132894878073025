import React from 'react';
import { Box, Grid, Paper } from '@mui/material';
import Avatar from '../Avatar/Avatar';
import { GlobalContext } from '../../Context/GlobalContext';

/**
 * Componente Layout de los bocadillos: <br>
 * Layout de los bocadillos dentro del chat, tanto para usuario y chatbot
 * @class Components/LayoutMessage
 * @example
 * import LayoutMessage from "../LayoutMessage/LayoutMessage"; <br>
 * <LayoutMessage withAvatar={withAvatar} typeUser={1} >
 * @param {*} withAvatar Variable para definir si el bocadillo viene con el avatar.
 * @param {*} typeUser Definir el tipo de bocadillo si es usuario o chatbot.
 * @param {*} isCarousel Define el esqueleto del mensaje si es carousel o no.
 * @param {*} children 
 * @returns {React.Fragment} LayoutMessage
 */
function LayoutMessage({ withAvatar=false, typeUser, isCarousel=false, children }) {

  const { cssApi } = React.useContext(GlobalContext);

  /**
   * Estilo del piquito del chatbot.
   */
  const PaperChatBotTailStyle = {
    width: '10px',
    top: 0,
    position: 'absolute',
    marginLeft: '-8px',
    fill: cssApi.chatbox_background_bot
  }
  /**
   * Estilo bocadillo del chatbot.
   */
  const PaperChatBotStyle = {
    bgcolor: cssApi.chatbox_background_bot,
    border: cssApi.chatbox_border,
    borderRadius: cssApi.chatbox_border_radius,
    color: cssApi.chatbox_color_bot,
    fontSize: cssApi.chatbox_font_size,
    maxWidth: "500px",
    p: '8px 14px'
  }

  /**
   * Estilo bocadillo del usuario.
   */
  const PaperUserStyle = {
    bgcolor: cssApi.chatbox_background_user,
    border: cssApi.chatbox_border,
    borderRadius: cssApi.chatbox_border_radius,
    borderTopRightRadius: '0px',
    color: cssApi.chatbox_color_user,
    fontSize: cssApi.chatbox_font_size,
    p: '8px 14px'
  }

  return (
    <Grid container={false} className="LayoutMessage" sx={{marginTop: '10px', marginBottom: '10px'}}>
      <Box display="flex" className="MsgBox" sx={typeUser===1 ? {position: 'relative'} : {justifyContent: 'flex-end' }}>
        <Avatar show={withAvatar}/>
        {isCarousel ?
            <React.Fragment>
              {children}
            </React.Fragment>
            :
            <Box className="MsgBox" display="flex">
              {withAvatar ? (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" style={PaperChatBotTailStyle} className="tail">
                <path d="M20 0H0l20 20z"></path>
              </svg>) : null}
              <Paper sx={typeUser===1 ? PaperChatBotStyle : PaperUserStyle} className={typeUser===1 ? 'MessageChatBot': 'MessageUser'} style={withAvatar ?  {borderTopLeftRadius: '0px'} : {}}>
                {children}
              </Paper>
            </Box>
        }
      </Box>
    </Grid>
  );
}

export default LayoutMessage;
