import React from "react";
import "./PhoneCodesSelect.css";
import { Icon, MenuItem, Select } from "@mui/material";
import countryOptions from "../../Constants/country_codes_selection.json"
import { checkDeviceType, focusInput, getFlagIcon } from "../../Utils/Utils";
import { WIDGET_MODE } from "../../Constants/Global";
import {GlobalContext} from "../../Context/GlobalContext";

const PhoneCodesSelect = () => {
  const { customApi } = React.useContext(GlobalContext);
  let [selected, setSelected] = React.useState(customApi.prefix ? customApi.prefix.replace("+", "") : 34);
  
  const handleSelect = (event) => {
    setSelected(event.target.value);
    focusInput("input-user");
  }

  return <Select
        key={"country-flags"}
        value={selected}
        onChange={handleSelect}
        sx={{
          width: "fit-content",
          maxHeight: "35px",
          "& .MuiInputBase-root": {
            maxHeight: "35px"
          },
            "& fieldset": {
                border: "0px"
            }
        }}
        MenuProps={{
          sx: {
              '& .MuiMenu-paper': {
                  maxHeight: '550px',
                  bottom: '50px'
              }
          }
      }}
      >
      {countryOptions.map((country) => 
        <MenuItem value={country.code} >
          <Icon className={ getFlagIcon(country.iso) } sx={{margin: "-5px 10px"}}></Icon>
          {checkDeviceType() === "Desktop" && !WIDGET_MODE && " " + country.country }
          {" (" + country.iso + ") " } <span className="country-code">{"+" + country.code + " " }</span>
        </MenuItem>
      )}  
      </Select>
}

export default PhoneCodesSelect;
