import { cyrb53, parseUrl } from '../Utils/Utils'
/**
 * Máximo de delay
 */
const MAX_DELAY = 3500;
/**
 * Multiplicador de delay
 */
const MULTIPLIER_DELAY = 10;
/**
 * Límite de botones antes de ser un selector
 */
const MAX_BUTTONS = 6;
/**
 * Detecta si estamos en modo Widget
 */
const WIDGET_MODE = !!document.querySelector("#hrbf-cell_root.hrbf-widget, #hrbf-cell_root.widget");
/**
 * Espacio del localStorage donde se guarda la sesión
 */

const PARSED_URL = parseUrl();

const REAL_URL = WIDGET_MODE ? PARSED_URL.hostname : PARSED_URL.realUri;

const SESSION_PREFIX = "gero.session."
const SESSION = SESSION_PREFIX+cyrb53(REAL_URL);

export {
    MAX_DELAY,
    MULTIPLIER_DELAY,
    MAX_BUTTONS,
    WIDGET_MODE,
    REAL_URL,
    PARSED_URL,
    SESSION_PREFIX,
    SESSION
}
