import React from 'react';
import './Typing.css';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import LayoutMessage from '../LayoutMessage/LayoutMessage';
import { GlobalContext } from '../../Context/GlobalContext';


/**
 * Componente del typing: <br>
 * Evento en el cual simula que el chatbot esta respondiendo.
 * @class Components/Typing
 * @example
 * import Typing from "../../Components/Typing/Typing";
 * <Typing />
 * @returns {React.Fragment} Typing
 */

function Typing() {
  const { cssApi } = React.useContext(GlobalContext);

  /**
   * Estilo css de los dot del elemento.
   */
  const Dot = styled('div')({
    background: cssApi.typing_color
  });
  
  return (
    <LayoutMessage 
      withAvatar={true} 
      typeUser={1}
    >
      <Stack direction="row" spacing={1} data-testid="typing">
        <Dot className="typing__dot"></Dot>
        <Dot className="typing__dot"></Dot>
        <Dot className="typing__dot"></Dot>
      </Stack>
    </LayoutMessage>
  );
}

export default Typing;
