import React from 'react';
import { styled } from "@mui/material/styles";
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { GlobalContext } from "../../Context/GlobalContext";

/**
 * Componente de Progreso del chat: <br>
 * Barra de progreso del chat alimentado por el API.
 * @class Components/ProgressBar
 * @example
 * import ProgressBar from "../../Components/ProgressBar/ProgressBar";
 * <ProgressBar />
 * @returns {React.Fragment} ProgressBar
 */

function ProgressBar() {
	const { cssApi, chatProgress } = React.useContext(GlobalContext);
	const [progress, setProgress] = React.useState(0);

	React.useEffect(() => {

		setProgress(chatProgress);
			
	}, [chatProgress]);

	/**
   * Componente LinearProgress de MUI con styled aplicado
   */
	const BorderLinearProgress = styled(LinearProgress)(() => ({
		height: "8px",
		borderBottom: "1px solid " + cssApi.background,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: "#eeeeee",
		},
		[`& .${linearProgressClasses.bar}`]: {
			backgroundColor: cssApi.progress_bar_color,
		},
	}));

	return (
		<Box sx={{ width: "100%" }}>
			<BorderLinearProgress variant="determinate" value={progress} />
		</Box>
	);
}

export default ProgressBar;