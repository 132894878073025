import React from "react";

import "./App.css";

import CssGlobal from "../Layout/CssGlobal/CssGlobal";
import Header from "../Layout/Header/Header";
import ContainerChat from "../Layout/ContainerChat/ContainerChat";
import ContainerButtons from "../Layout/ContainerButtons/ContainerButtons";
import Footer from "../Layout/Footer/Footer";
import LoadingPage from "../Layout/LoadingPage/LoadingPage";
import WidgetDialog from "../Layout/WIdgetDialog/WidgetDialog";

import { GlobalProvider } from "../Context/GlobalContext";
import { TimerProvider } from "../Context/TimerContext";
import { HelmetProvider } from "react-helmet-async";
import { SESSION, WIDGET_MODE, REAL_URL, SESSION_PREFIX } from "../Constants/Global";

function App() {
  const [layoutKey, setLayoutKey] = React.useState(0);

  window.removeSession = (status) =>{
    localStorage.removeItem(SESSION);
    setLayoutKey((prev) => status || prev + 1);
  };

  window.removeAllSessions =  () => {
    var localStorageLength = localStorage.length;
    for (var i = 0; i < localStorageLength; i++) {
      var key = localStorage.key(i);
      if (key.startsWith(SESSION_PREFIX)) {
        localStorage.removeItem(key);
      }
    }
  }

  // Si nos llega el parámetro renew, forzamos a borrar localStorage,
  // pero mantenemos la key con la url sin dicho parámetro (https://app.clickup.com/t/86bx8hmbk)
  if(window.location.search.indexOf('&renew=') > -1 || window.location.search.indexOf('?renew=') > -1) {
    window.history.replaceState({}, '', REAL_URL);
    localStorage.removeItem(SESSION);
  }

const layout = (
  <>
    <HelmetProvider>
      <CssGlobal widgetMode={WIDGET_MODE}>
        <Header />
        <TimerProvider key={layoutKey}>
          <ContainerChat />
          <ContainerButtons />
        </TimerProvider>
        <Footer />
        <LoadingPage />
      </CssGlobal>
    </HelmetProvider>
  </>
);

const wrappedLayout = (
  <GlobalProvider key={layoutKey}>
    {WIDGET_MODE ? <WidgetDialog>{layout}</WidgetDialog> : layout}
  </GlobalProvider>
);

return wrappedLayout;
}

export default App;
