import React from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
import App from './App/App';
import CssBaseline from '@mui/material/CssBaseline';


const root = ReactDOM.createRoot(document.getElementById('hrbf-cell_root'));
root.render(
  <div>
    <CssBaseline />
    <App />
  </div>
);

