import React from 'react';
import './Scheduling.css';
import { GlobalContext } from '../../Context/GlobalContext';
import Cal, { getCalApi } from "@calcom/embed-react";


/**
 * Componente del scheduling center: <br>
 * Muestra el calendario del Sheduling Center de un evento pasando su link.
 * @class Components/Scheduling
 * @example
 * import Scheduling from "../../Components/Scheduling/Scheduling";
 * <Scheduling link="path" />
 * @returns {JSX.Element} Scheduling
 */

function Scheduling({link}) {
  const { cssApi, pushChatMessages } = React.useContext(GlobalContext);

  React.useEffect(()=>{
    (async function () {
      const cal = await getCalApi();
      cal("ui", {"theme":"light","styles":{"branding":{"brandColor": cssApi.background}}});
      cal("on", {
        action: "*",
        callback: (e) => {
          console.log(e.detail);
          if(e.detail.type === "bookingSuccessful"){
            pushChatMessages(
                {
                  messages: [{ selected_text: "Confirmed", type_text: "text" }],
                  postback: "confirmed",
                  text: "Confirmed",
                },
                "user"
            );
          }
        }
      });
    })();
    // eslint-disable-next-line
  }, []);

  return <Cal calLink={link} style={{width:"100%",height:"100%",overflow:"scroll"}}
              calOrigin="https://scheduling.hrbotfactory.com/"
              embedJsUrl="https://scheduling.hrbotfactory.com/embed/embed.js"
  />;
}

export default Scheduling;
