import "./WidgetDialog.css";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Avatar from "../../Components/Avatar/Avatar";
import {IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {GlobalContext} from "../../Context/GlobalContext";
import { scrollDownEvent } from "../../Utils/Utils";

function WidgetDialog({ children }) {
  const [open, setOpen] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);
  const { cssApi } = React.useContext(GlobalContext);

  React.useEffect(()=>{
      const widget_open = window.gero?.widget_open || window.widget_open;
      if(widget_open){
          setTimeout(()=> handleClickOpen(), widget_open);
      }
  },[]);

  const handleClickOpen = () => {
    setOpen(true);
    setHidden(false);
    scrollDownEvent();
  };

  const handleClose = (event, reason) => {
    if(document.body && document.body.style) document.body.style.overflow="initial";
    if (reason && reason === "backdropClick") return;
    //setOpen(false); //Warn: Open == false destroy component
    setHidden(true);
    return event;
  };

  const get_avatar_url = () =>{
      const client = window.gero?.client || window.client;
      const avatar = window.gero?.avatar || "avatar.png";
      return "https://d2t3vz512yrf7u.cloudfront.net/clients/"+client+"/"+avatar;
  }

  const WidgetStyle = { 
    borderRadius: cssApi.widget_border_radius,
  }

  return (
    <React.Fragment>
      {cssApi.ui_replace_avatar ? (
        <div className="widget__button-text" onClick={handleClickOpen}>
          {cssApi.text_avatar}
        </div>
      ) : (
        <Avatar className="widget__button" onClick={handleClickOpen} show={true} image={get_avatar_url()} />
      )}
      <Dialog
        sx={{ display: hidden ? "none" : "flex" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        className="widget-dialog"
      >
        <DialogContent className="widget-dialog__content" style={WidgetStyle} >
            <IconButton  id="close_widget"  size="large"  edge="end"  color="inherit"
                aria-controls={open ? "basic-menu" : undefined}  aria-haspopup="true" aria-expanded={open ? "true" : undefined}
                aria-label="close_widget"
                onClick={handleClose}
            >
                <CloseIcon />
            </IconButton>
            {children}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default WidgetDialog;
