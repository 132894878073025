import React from "react";
import "./ContainerButtons.css";
import { styled } from "@mui/material/styles";
import { Paper, Box, Container, Alert, AlertTitle } from "@mui/material";
import AttachFileButtons from "../../Components/AttachFileButtons/AttachFileButtons";
import PhotoCameraButtons from "../../Components/PhotoCameraButtons/PhotoCameraButtons";
import VideoCameraButton from "../../Components/VideoCameraButton/VideoCameraButton";
import InputUserButtons from "../../Components/InputUserButtons/InputUserButtons";
import { GlobalContext } from "../../Context/GlobalContext";
import { checkType } from "../../Utils/Utils";
import { AxiosService } from "../../Services/AxiosService";
import AudioButtons from "../../Components/AudioButtons/AudioButtons";

/**
 * Componente contenedor de la barra de input: <br>
 * Contiene toda la interaccion entre el chatbot y el usuario.
 * @class Layout/ContainerButtons
 * @example
 * import ContainerButtons from '../Layout/ContainerButtons/ContainerButtons';
 * <ContainerButtons />
 * @returns {React.Component} ContainerButtons
 */
function ContainerButtons() {
  const {
    cssApi,
    pushChatMessages,
    inputPlaceholder,
    inputDisabled,
    inputType,
    inputMultiple,
    fileDisabled,
    photoCameraDisabled,
    videoCameraDisabled,
    audioDisabled,
    customApi,
  } = React.useContext(GlobalContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [alert, setAlert] = React.useState("");
  const refButtons = React.useRef(null);
  /**
   * Componente Paper de MUI con styled aplicado
   */
  const ButtonsPaper = styled(Paper)({
    alignItems: "center",
    background: cssApi.input_background,
    boxShadow: "none",
    borderRadius: "0px",
    color: "red",
    display: "flex",
    padding: "2px 14px",
    width: "100%",
    "& .MuiInputBase-input": {
      color: cssApi.input_color,
    },
    "& .MuiInputBase-input::placeholder": {
      color: cssApi.input_placeholder_color,
    },
    "@media (max-width: 600px)": {
      padding: "2px 6px",
    },
  });

  const ButtonsBar = styled(Box)({
    background: cssApi.input_background,
  });

  /**
   * Convierte la lista de urls en mensajes con preview y los añade en el chat
   * @param {*} data respuesta tras enviar ficheros con las url
   */
  const viewChatFiles = (data) => {
    const urls = data.map((v) => v.url);
    let messages = [];

    data.forEach(({preview, url}) => {
      let parts = url.split("/");
      let fileName = parts[parts.length - 1];

      messages.push({
        fileUrl: preview,
        fileName: fileName,
        type_text: checkType(url),
      });
    });
    let postback = urls.join("; ");
    pushChatMessages({ messages, postback: postback, text: postback }, "user");
  };

  /**
   * Hace la subida de ficheros al servidor y muestra la respuesta en previews
   * @param {*} files ficheros que se han seleccionado
   */
  const uploadFiles = async (files) => {
    setIsLoading(true);
    return AxiosService.postFiles(customApi.session, files)
      .then((res) => {
        viewChatFiles(res.data);
      })
      .catch((err) => {
        setAlert(err.message);
        setTimeout(() => {
          setAlert("");
        }, 2500);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <section className="containerButtons">
      {alert ? (
        <Alert severity="error">
          <AlertTitle>Error: {alert}</AlertTitle>
        </Alert>
      ) : (
        <ButtonsBar className="chatbothr_containerbuttons" ref={refButtons}>
          <Container fixed maxWidth="md">
            <ButtonsPaper component="form">
              <InputUserButtons
                inputPlaceholder={inputPlaceholder}
                inputDisabled={inputDisabled}
                inputType={inputType}
              />
              {!fileDisabled && (
                <AttachFileButtons
                  cssApi={cssApi}
                  uploadFiles={uploadFiles}
                  isLoading={isLoading}
                  multiple={inputMultiple}
                />
              )}
              {!photoCameraDisabled && (
                <PhotoCameraButtons
                  cssApi={cssApi}
                  uploadFiles={uploadFiles}
                  isLoading={isLoading}
                  multiple={inputMultiple}
                />
              )}
              {!videoCameraDisabled && (
                <VideoCameraButton
                  cssApi={cssApi}
                  uploadFiles={uploadFiles}
                  isLoading={isLoading}
                />
              )}
              {!audioDisabled && (
                <AudioButtons
                  cssApi={cssApi}
                  uploadFiles={uploadFiles}
                  isLoading={isLoading}
                />
              )}
            </ButtonsPaper>
          </Container>
        </ButtonsBar>
      )}
    </section>
  );
}

export default ContainerButtons;
