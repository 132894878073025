import React from "react";
import "./VideoCameraButton.css";
import { styled } from "@mui/material/styles";
import { IconButton, CircularProgress } from "@mui/material";
import Icon from "@mui/icons-material/PhotoCamera";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import WebCamRecorder from '../WebCamRecorder/WebCamRecorder';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'
import { GlobalContext } from "../../Context/GlobalContext";
import {Timer} from "../Timer/Timer";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

/**
 * Componente de camara o video: <br>
 * Botón de tomar o una foto o un video .
 * @class Components/VideoCameraButton
 * @example
 * const {cssApi} = React.useContext(GlobalContext);
 * <VideoCameraButton cssApi={cssApi} />
 * @param {*} cssApi estilos cargados desde el api
 * @returns {React.Fragment} VideoCameraButton
 */
function VideoCameraButton({ cssApi, uploadFiles, isLoading = false }) {
  const { t } = useTranslation();
  const { lastMessage } = React.useContext(GlobalContext);

  /**
   * Componente IconButton de MUI con styled aplicado
   */
  const CameraIconButton = styled(IconButton)({
    color: cssApi.input_placeholder_color,
    p: "10px",
  });

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const uploadEnd = () => {
    setOpen(false)
  }

  /**
   * Maneja el evento onClick del botón y lanza la camara.
   */
  const toggleModal = () => {
    if(open) {
      handleClose()
    } else {
      handleOpen()
    }
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <React.Fragment>
      <BootstrapDialog
        sx={{background: cssApi.background}}
        open={open}
        fullWidth={true}
        fullScreen={isSmallScreen}
        maxWidth="lg"
        onClose={() => {return}}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">{t("videocamerabutton.dialog.title")}</DialogTitle>

        {lastMessage && lastMessage.reply_time && <Timer className="big" style={{ position: "absolute", right: "4rem", top: "8px" }}/>}

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent dividers>
          {lastMessage && lastMessage.selected_text && <p className="videocamerabutton-last-message" dangerouslySetInnerHTML={{ __html: lastMessage.selected_text }} />}
          <WebCamRecorder uploadFiles={uploadFiles} onUploadEnd={uploadEnd} showSources={true}/>
        </DialogContent>
      </BootstrapDialog>

      {isLoading ? (
        <CircularProgress
          aria-label="circular_progress"
          style={{ color: "grey", padding: "8px" }}
        />
      ) : (
        <CameraIconButton
          aria-label="photocamera"
          onClick={toggleModal}
        >
          <Icon />
        </CameraIconButton>
      )}
    </React.Fragment>
  );
}

export default VideoCameraButton;
