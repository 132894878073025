import React from "react";
import { styled } from "@mui/material/styles";
import { IconButton, CircularProgress } from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

/**
 * Componente de camara o video: <br>
 * Botón de tomar o una foto o un video .
 * @class Components/PhotoCameraButtons
 * @example
 * const {cssApi} = React.useContext(GlobalContext);
 * <PhotoCameraButtons cssApi={cssApi} />
 * @param {*} cssApi estilos cargados desde el api
 * @returns {React.Fragment} PhotoCameraButtons
 */
function PhotoCameraButtons({ cssApi, uploadFiles, multiple, isLoading = false }) {
  const inputPhoto = React.useRef(null);

  /**
   * Maneja el evento onClick del botón y lanza la camara.
   */
  const handleClickPhotoCamera = () => {
    inputPhoto.current.click();
  };

  /**
   * Maneja el evento onSelect del input y envía la foto o el video realizado.
   */
  const handleSelect = (event) => {
    uploadFiles([...event.target.files]);
  };

  /**
   * Componente IconButton de MUI con styled aplicado
   */
  const CameraIconButton = styled(IconButton)({
    color: cssApi.input_placeholder_color,
    p: "10px",
  });


  const getMultiple = () => {
    console.log("multiple", multiple);
    return multiple ? {multiple: true} : {};
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <CircularProgress
          aria-label="circular_progress"
          style={{ color: "grey", padding: "8px" }}
        />
      ) : (
        <CameraIconButton
          aria-label="photocamera"
          onClick={handleClickPhotoCamera}
        >
          <PhotoCameraIcon />
          <input
            data-testid="photo_attach"
            id="photo_attach"
            style={{ display: "none" }}
            type="file"
            {...getMultiple()}
            capture="environment"
            ref={inputPhoto}
            onChange={handleSelect}
          />
        </CameraIconButton>
      )}
    </React.Fragment>
  );
}

export default PhotoCameraButtons;
