import React from "react";
import "./Footer.css";
import { styled } from '@mui/material/styles';
import { Link, Stack } from "@mui/material";
import { GlobalContext } from "../../Context/GlobalContext";

/**
 * Componente Pie de página: <br>
 * Contiene links personalizables del usuario.
 * @class Layout/Footer
 * @example
 * import Footer from '../Layout/Footer/Footer';
 * <Footer />
 * @returns {React.Component} Footer
 */

function Footer() {
  const { cssApi, footerApi } = React.useContext(GlobalContext);

  /**
   * Estilo css con styled aplicado
   */
  const FooterHr = styled('div')({
    backgroundColor: cssApi.footer_background,
    borderTop: cssApi.footer_border,
    color: cssApi.footer_color,
    fontFamily: cssApi.footer_font_family,
    fontSize: cssApi.footer_font_size,
    textDecorationColor: cssApi.footer_color,
  });

  /**
   * Componente Link de MUI con styled aplicado
   */
  const LinkFooter = styled(Link)({
    cursor: 'pointer',
    color: cssApi.footer_color,
    '&:visited': { 
      color: cssApi.footer_color_visited
    },
    '&:hover': { 
      color: cssApi.footer_color_hover
    }
  });

  return footerApi.length && (
    <FooterHr className="chatbothr_footer">
      <Stack spacing={1}>
        {footerApi.map((link) => (
          <LinkFooter 
            key={link.url} 
            href={link.url} 
            target="_blank"
          >
            {link.label}
          </LinkFooter>
        ))}
      </Stack> 
    </FooterHr>
  );
}

export default Footer;
