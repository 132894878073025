import Axios from "axios";
//import axiosRetry from 'axios-retry';
import { objectToQueryString } from "../Utils/Utils";
const AxiosTimeout = 90000;
/**
 * Servicio de peticiones http al servidor: <br>
 * Se encarga de la comunicación con el API mediante los endpoint disponibles
 * @class Services/AxiosService
 * @prop {function} getStart - devuelve una promesa con skin y saludo
 * @prop {function} getHistory - devuelve una promesa con skin y conversación recuperada @param sessionId
 * @prop {function} postFiles - envía ficheros y devuelve una promesa con las url @param sessionId @param files
 * @prop {function} postReply - envía una respuesta del usuario y devuelve una promesa con la respuesta del bot @param sessionid @param postback @param text
 */
export const AxiosService = {
  config() {
    let botUrl = window.location.href;
    if(botUrl.indexOf('preview=') > -1) {
      let url = new URL(botUrl)
      url.searchParams.delete('preview');

      botUrl = url.toString()
    }

    return {
      timeout: AxiosTimeout,
      headers: {
        "Bot-Url": botUrl,
        "Bot-Handler": window.gero?.handler,
        "ngrok-skip-browser-warning": "skip"
      }
    };
  },
  getStart: async (widget_mode=false, parsedData={}) => {
    let search_params = window.location.search;
    let botUrl = window.location.href;
    if(botUrl.indexOf('preview=') > -1) {
      let url = new URL(botUrl)
      url.searchParams.delete('preview');

      search_params = '?' + url.searchParams.toString()
    }

    search_params += (search_params.length ? "&": "?") + "widget_mode="+widget_mode;
    search_params += "&"+objectToQueryString(parsedData);
    return await Axios.all([
      Axios.get(
        `${process.env.REACT_APP_CHATBOT_URL_API_CUSTOMIZATION}`, AxiosService.config()
      ),
      Axios.get(
        `${process.env.REACT_APP_CHATBOT_URL_API_START}${search_params}`, AxiosService.config()
      ),
    ]);
  },

  getHistory: async (sessionId) => {
    return await Axios.all([
      Axios.get(
        `${process.env.REACT_APP_CHATBOT_URL_API_CUSTOMIZATION}`, AxiosService.config()
      ),
      Axios.get(
        `${process.env.REACT_APP_CHATBOT_URL_API_LOAD}/${sessionId}`, AxiosService.config()
      ),
    ]);
  },

  postFiles: async (sessionId, files) => {
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append("file"+index, file);
    });
    return await Axios({
      method: "POST",
      url: `${process.env.REACT_APP_CHATBOT_URL_API_UPLOAD}/${sessionId}`,
      data: formData,
      timeout: AxiosTimeout,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  setVars: async (sessionId, vars) => {
    return await Axios.post(
        `${process.env.REACT_APP_CHATBOT_URL_API_VARS}/${sessionId}`,
        vars,
        {timeout: AxiosTimeout}
    )
  },
  postReply: async (sessionId, postback, text) => {
    return await Axios.post(
        `${process.env.REACT_APP_CHATBOT_URL_API_REPLY}/${sessionId}`,
        { postback: postback, text: text},
        {timeout: AxiosTimeout}
    )
  },
};
